export const CATEGORIES = {
    "Syllabuses": "outline of subjects to be covered in networking courses",
    "Videos and Online Courses": "courses and videos that can teach you more about networking", 
    "Learning Management Systems": "systems to manage course functions online",
    "SDN Controllers": "server software for the “brains” of a software-defined network",
    "Videocasting Platforms": "software to help you record and broadcast videos",
    "Testbeds": "resources to help you build physical or virtual testbeds",
    "Videos": "videos that can teach you more about networking", 
    "Examination Platforms": "platforms to help you host exams/quizzes online",
    "Professional Societies": "groups you can join to meet other people interested in computer networking",
    "Interaction Platforms": "platforms to help break the ice and create interactions between your students and team members",
    "Network Tools": "tools to generate packets, analyze probes, and other sorts of actions with networks",
    "Homeworks/Lab Assignments": "already-created assignments you can do or assign to your students to learn more about networking", 
    "Vendor Design Guides": "vendor-specific articles and marketing materials",
    "Books/Documentations": "online or physical books about networking and their supporting websites/materials", 
    "Publishing/Document Writing": "platforms to help you write, typeset, and disseminate documents", 
    "Network Device Implementations": "resources to help you implement or run your own network routers/switches/etc.",
    "Video Calling Platforms": "platforms that can help you make and host online meetings",
    "Data Repositories": "sites that have big bunches of real network traces that you can use for testing and such",
    "Software Development": "resources that can help you develop software",
    "Visualizations/Analyzers": "resources that can help you visualize aspects of computer networks", 
    "Event Hosting": "platforms to help you host the online part of virtual or hybrid conferences and other events",
    "Games": "games that help teach networking",
    "Audio/Podcasts": "platforms that enable creation of websites and other infrastructure",
    "Network Emulators": "platforms that help you set up testbeds of emulated networks",
    "Mailing Lists and News Feeds": "places you can get up to date news and updates on computer networking",
    "Infrastructures": "software and cloud-based databases",
    "Simulators": "platforms to help you simulate networks [please change the name to “Simulators” and also add AirSim and Gazebo"
}